import React from 'react';
import { Link } from 'gatsby';

import '../../reusable/assets/css/popular-tags.scss';

/* Would these be imported from Dato? */

export default () => {

  const popularTags = [
    "Coffee",
    "Latte Art",
    "Espresso",
    "Grinder",
    "Roast",
    "Mocha",
    "Recipes",
    "Guide",
    "Beans",
    "Home",
    "Top",
    "Drip",
    "Ground",
    "Decaf",
    "How To"
  ];

  const splitLinks = links => {
    
    let tagGroups = [];
    const chunk = 5;
  
    const getTags = (group) => group.map( (tag, i) => <li key={i}><Link to="#">{tag}</Link></li> );

    for (let i = 0; i < links.length; i += chunk) {
      let tags = links.slice(i, i + chunk);

      tagGroups.push(tags);
    }

    return tagGroups.map( (group, i) => <ul key={i}>{ getTags(group) }</ul>);
  }


const createTagLinks = links => splitLinks(links);

  return (
    <section className="search-popular-tags">
      <header><h2>Popular Tags</h2></header>
      <nav>
        { createTagLinks(popularTags) }
      </nav>
    </section>
  );

}