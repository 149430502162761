import React, { Fragment } from "react"
import { Highlight, Snippet } from "react-instantsearch-dom"
import { Link } from "gatsby"
import { Calendar } from "styled-icons/octicons/Calendar"
import { Tags } from "styled-icons/fa-solid/Tags"

export const BlogHit = clickHandler => ({ hit, location }) => {

  console.log('hit in bloghit: ', hit)
  console.log('location: ', location);
  const { 
    headerImage: {
      resolutions: {
        src
      }
    },
    handle  
  } = hit;

  return (
    <div>
    <Link to={hit.handle} onClick={clickHandler}>
      <img src={src} alt={handle} />
      <p>{handle}</p>
    </Link>
    <Snippet attribute="excerpt" hit={hit} tagName="mark" />
  </div>
  );
}

export const PostHit = clickHandler => ({ hit }) => {
  
  return (
  <div>
    <Link to={hit.handle} onClick={clickHandler}>
      <h4>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </h4>
    </Link>
    <div>
      <Calendar size="1em" />
      &nbsp;
      <Highlight attribute="date" hit={hit} tagName="mark" />
      &emsp;
      <Tags size="1em" />
      &nbsp;
      {hit.tags.map((tag, index) => (
        <Fragment key={tag}>
          {index > 0 && `, `}
          {tag}
        </Fragment>
      ))}
    </div>
    <Snippet attribute="excerpt" hit={hit} tagName="mark" />
  </div>
  )
      }