import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';

import ExploreCategories from '../components/reusable/ExploreCategories';
import PopularTags from '../components/search/page-components/popularTags';
import NewsletterSignup from '../components/reusable/newsletterSignup';


import Search from '../components/search';

import '../components/assets/css/search.scss';


const searchIndices = [
  // { name: `Pages`, title: `Pages`, hitComp: `PageHit` },
  { name: `Blogs`, title: `Blogs`, hitComp: `BlogHit` },
  // { name: `Authors`, title: `Authors`, hitComp: `AuthorHit` },
];



export default ({ data }) => (
  <Layout>   
    <div className="search-header">
      <header><h4>What are you looking for?</h4></header>
      <Search indices={searchIndices} />
    </div>
    <ExploreCategories />
    <PopularTags />
    <NewsletterSignup />
  </Layout>
);

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`